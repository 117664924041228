import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { Spin, Table, Typography, Row, Col } from 'antd';

import './DomainResultComponent.css'
import ReactEcharts from 'echarts-for-react';
import TaskDataControlComponent from "./TaskDataControlComponent";
import config from '../../config'; // 导入配置文件

const { Title } = Typography;

const DomainResultComponent = () => {
  const [loadingStates, setLoadingStates] = useState({
    dnsCtrlRate: false,
    domainCtrlRate: false,
    nodeCtrlRate: false,
    taskCtrlRate: false,
    timeoutRate: false,
    fullControl: false,
    fullEscape: false,
    reError: false,
  });

  const [dnsCtrlRateData, setDnsCtrlRateData] =  useState({data: [], total: 0});
  const [domainCtrlRateData, setDomainCtrlRateData] =  useState({data: [], total: 0});
  const [nodeCtrlRateData, setNodeCtrlRateData] = useState({data: [], total: 0});
  const [taskCtrlRateData, setTaskCtrlRateData] =  useState({data: [], total: 0});
  const [timeoutRateData, setTimeoutRateData] =  useState({data: [], total: 0});
  const [fullControlData, setFullControlData] =  useState({data: [], total: 0});
  const [fullEscapeData, setFullEscapeData] =  useState({data: [], total: 0});
  const [reErrorData, setReErrorData] =  useState({data: [], total: 0});

  const [currentPageTaskCtrl, setCurrentPageTaskCtrl] = useState(1);
  const [pageSizeTaskCtrl, setPageSizeTaskCtrl] = useState(10);

  const [currentPageNodeCtrl, setCurrentPageNodeCtrl] = useState(1);
  const [pageSizeNodeCtrl, setPageSizeNodeCtrl] = useState(10);

  const [currentPageDomainCtrl, setCurrentPageDomainCtrl] = useState(1);
  const [pageSizeDomainCtrl, setPageSizeDomainCtrl] = useState(10);

  const [currentPageDnsCtrl, setCurrentPageDnsCtrl] = useState(1);
  const [pageSizeDnsCtrl, setPageSizeDnsCtrl] = useState(10);

  const [currentPageFullEscape, setCurrentPageFullEscape] = useState(1);
  const [pageSizeFullEscape, setPageSizeFullEscape] = useState(10);

  const [currentPageFullControl, setCurrentPageFullControl] = useState(1);
  const [pageSizeFullControl, setPageSizeFullControl] = useState(10);

  const [currentPageReError, setCurrentPageReError] = useState(1);
  const [pageSizeReError, setPageSizeReError] = useState(10);

  useEffect(() => {
    const fetchData = async () => {
      setLoadingStates(prev => ({ ...prev, taskCtrlRate: true }));
      try {
        const response = await axios.get(`${config.globalUrl}/dns-task-ctrl-rate-data?page=${currentPageTaskCtrl}&pageSize=${pageSizeTaskCtrl}`);
        const { data, total } = response.data; // Destructure the response
        setTaskCtrlRateData({ data, total });
        setLoadingStates(prev => ({ ...prev, taskCtrlRate: false }));
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoadingStates(prev => ({ ...prev, taskCtrlRate: false }));
      }
    };
    fetchData();
  }, [currentPageTaskCtrl, pageSizeTaskCtrl]); // 当页码或pageSize变化时重新获取数据

  useEffect(() => {
    const fetchData = async () => {
      setLoadingStates(prev => ({ ...prev, nodeCtrlRate: true }));
      try {
        const response = await axios.get(`${config.globalUrl}/dns-node-ctrl-rate-data?page=${currentPageNodeCtrl}&pageSize=${pageSizeNodeCtrl}`);
        const { data, total } = response.data; // Destructure the response
        setNodeCtrlRateData({ data, total });
        setLoadingStates(prev => ({ ...prev, nodeCtrlRate: false }));
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoadingStates(prev => ({ ...prev, nodeCtrlRate: false }));
      }
    };
    fetchData();
  }, [currentPageNodeCtrl, pageSizeNodeCtrl]); // 当页码或pageSize变化时重新获取数据

  useEffect(() => {
    const fetchData = async () => {
      setLoadingStates(prev => ({ ...prev, domainCtrlRate: true }));
      try {
        const response = await axios.get(`${config.globalUrl}/dns-domain-ctrl-rate-data?page=${currentPageDomainCtrl}&pageSize=${pageSizeDomainCtrl}`);
        const { data, total } = response.data; // Destructure the response
        setDomainCtrlRateData({ data, total });
        setLoadingStates(prev => ({ ...prev, domainCtrlRate: false }));
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoadingStates(prev => ({ ...prev, domainCtrlRate: false }));
      }
    };
    fetchData();
  }, [currentPageDomainCtrl, pageSizeDomainCtrl]);

  useEffect(() => {
    const fetchData = async () => {
      setLoadingStates(prev => ({ ...prev, dnsCtrlRate: true }));
      try {
        const response = await axios.get(`${config.globalUrl}/dns-ctrl-rate-data?page=${currentPageDnsCtrl}&pageSize=${pageSizeDnsCtrl}`);
        const { data, total } = response.data; // Assuming the backend is modified to return {data, total}
        setDnsCtrlRateData({ data, total });
        setLoadingStates(prev => ({ ...prev, dnsCtrlRate: false }));
      } catch (error) {
        console.error('Error fetching DNS control rate data:', error);
        setLoadingStates(prev => ({ ...prev, dnsCtrlRate: false }));
      }
    };
    fetchData();
  }, [currentPageDnsCtrl, pageSizeDnsCtrl]);

  useEffect(() => {
    const fetchData = async () => {
      setLoadingStates(prev => ({ ...prev, fullEscape: true }));
      try {
        const response = await axios.get(`${config.globalUrl}/dns-full-escape-data?page=${currentPageFullEscape}&pageSize=${pageSizeFullEscape}`);
        const { data, total } = response.data; // Assuming the backend is modified to return {data, total}
        setFullEscapeData({ data, total });
        setLoadingStates(prev => ({ ...prev, fullEscape: false }));
      } catch (error) {
        console.error('Error fetching full escape data:', error);
        setLoadingStates(prev => ({ ...prev, fullEscape: false }));
      }
    };
    fetchData();
  }, [currentPageFullEscape, pageSizeFullEscape]);

  useEffect(() => {
    const fetchData = async () => {
      setLoadingStates(prev => ({ ...prev, fullControl: true }));
      try {
        const response = await axios.get(`${config.globalUrl}/dns-full-control-data?page=${currentPageFullControl}&pageSize=${pageSizeFullControl}`);
        const { data, total } = response.data; // Assuming the backend is modified to return {data, total}
        setFullControlData({ data, total });
        setLoadingStates(prev => ({ ...prev, fullControl: false }));
      } catch (error) {
        console.error('Error fetching full control data:', error);
        setLoadingStates(prev => ({ ...prev, fullControl: false }));
      }
    };
    fetchData();
  }, [currentPageFullControl, pageSizeFullControl]);

  useEffect(() => {
    const fetchData = async () => {
      setLoadingStates(prev => ({ ...prev, reError: true }));
      try {
        const response = await axios.get(`${config.globalUrl}/dns-re-error-data?page=${currentPageReError}&pageSize=${pageSizeReError}`);
        const { data, total } = response.data; // Destructure the response
        setReErrorData({ data, total });
        setLoadingStates(prev => ({ ...prev, reError: false }));
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoadingStates(prev => ({ ...prev, reError: false }));
      }
    };
    fetchData();
  }, [currentPageReError, pageSizeReError]);

  const taskCtrlDataColumns = [
    {
      title: '时间',
      dataIndex: 'time',
      key: 'time',
    },
    {
      title: '控制率',
      dataIndex: 'ctrlRate',
      key: 'ctrlRate',
    },
  ];

  const TaskCtrlOption = () => {
    // Sort the data by time to ensure the chart follows chronological order
    const sortedData = taskCtrlRateData.data.sort((a, b) => new Date(a.time) - new Date(b.time));

    // Extract the xAxis data and the series data
    const xAxisData = sortedData.map(({ time }) => time.split('T')[0] +'-'+ time.split('T')[1]); // Assuming ISO string format, adjust as needed
    const seriesData = sortedData.map(({ ctrlRate }) => ctrlRate);

    return {
      tooltip: {
        trigger: 'axis',
        formatter: (params) => {
          // console.log(params)
          let result = `${params[0].axisValue}<br/>`;
          params.forEach(param => {
            result += `${param.marker}${param.seriesName}: ${param.data}<br/>`;
          });
          return result;
        }
      },
      xAxis: {
        type: 'category',
        data: xAxisData,
        axisLabel: {
          formatter: (value) => value, // Directly use the value if it's already formatted
        }
      },
      yAxis: {
        type: 'value',
        name: '控制率',
        axisLabel: {
          formatter: '{value}'
        }
      },
      series: [{
        name: '控制率',
        type: 'line',
        data: seriesData,
        smooth: true, // Optional: for a smoother line chart
      }]
    };
  };

  const nodeCtrlDataColumns = [
    {
      title: '时间',
      dataIndex: 'time',
      key: 'time',
    },
    {
      title: '节点',
      dataIndex: 'node',
      key: 'node',
    },
    {
      title: '控制率',
      dataIndex: 'ctrlRate',
      key: 'ctrlRate',
    },
  ];

  const NodeCtrlOption = () => {
    // Assuming nodeCtrlRateData.data is the array you want to sort
    const sortedData = nodeCtrlRateData.data.sort((a, b) => new Date(a.time) - new Date(b.time));

    // Generate node names list dynamically
    const nodes = [...new Set(sortedData.map(entry => entry.node))];

    // Use a Map to store data for each timestamp
    const timeNodeMap = new Map();
    sortedData.forEach(({ time, node, ctrlRate }) => {
      if (!timeNodeMap.has(time)) {
        timeNodeMap.set(time, {});
      }
      timeNodeMap.get(time)[node] = ctrlRate;
    });

    // Construct series data for the chart
    const seriesData = nodes.map(node => ({
      name: `Node ${node}`,
      type: 'line',
      data: Array.from(timeNodeMap.keys()).map(time => timeNodeMap.get(time)[node] || null),
    }));

    return {
      tooltip: { trigger: 'axis' },
      legend: { data: nodes.map(node => `Node ${node}`) },
      xAxis: { type: 'category', data: Array.from(timeNodeMap.keys()) },
      yAxis: { type: 'value' },
      series: seriesData,
    };
  };

  const domainCtrlDataColumns = [
    {
      title: '时间',
      dataIndex: 'time',
      key: 'time',
    },
    {
      title: '域名',
      dataIndex: 'domain',
      key: 'domain',
    },
    {
      title: '控制率',
      dataIndex: 'ctrlRate',
      key: 'ctrlRate',
    },
  ];

  const DomainCtrlOption = () => {
    // 对时间进行排序
    const xAxisData = [...new Set(domainCtrlRateData.data.map(({ time }) => time))].sort();

    // 初始化每个域名的数据结构
    const seriesData = domainCtrlRateData.data.reduce((acc, { time, domain, ctrlRate }) => {
      if (!acc[domain]) {
        acc[domain] = xAxisData.map(x => ({ time: x, ctrlRate: null })); // 为每个时间点初始化空数据
      }
      const index = acc[domain].findIndex(item => item.time === time);
      if (index !== -1) {
        acc[domain][index].ctrlRate = ctrlRate; // 更新控制率
      }
      return acc;
    }, {});

    // 构建ECharts series数据
    const series = Object.keys(seriesData).map(domain => ({
      name: domain,
      type: 'line',
      data: seriesData[domain].map(item => item.ctrlRate), // 现在这里的数据已经与x轴对齐
    }));

    return {
      tooltip: {
        trigger: 'axis',
        formatter: (params) => {
          let result = `${params[0].axisValue}<br/>`;
          params.forEach(param => {
            result += `${param.seriesName}: ${param.value}<br/>`;
          });
          return result;
        }
      },
      legend: {
        data: Object.keys(seriesData),
      },
      xAxis: {
        type: 'category',
        data: xAxisData,
        axisLabel: {
          formatter: function (value) {
            return value.split('T')[1]; // 如果你的时间数据包含日期和时间，这里只显示时间
          }
        }
      },
      yAxis: {
        type: 'value',
        name: 'Control Rate',
        axisLabel: {
          formatter: '{value}'
        }
      },
      series: series
    };
  };

  const dnsCtrlDataColumns = [
    {
      title: '时间',
      dataIndex: 'time',
      key: 'time',
    },
    {
      title: 'DNS服务器ip',
      dataIndex: 'dns',
      key: 'dns',
    },
    {
      title: '控制率',
      dataIndex: 'ctrlRate',
      key: 'ctrlRate',
    },
  ];

  const DnsCtrlOption = () => {
    // 对时间进行排序
    const xAxisData = [...new Set(dnsCtrlRateData.data.map(({ time }) => time))].sort();

    // 初始化每个DNS的数据结构
    const seriesData = dnsCtrlRateData.data.reduce((acc, { time, dns, ctrlRate }) => {
      if (!acc[dns]) {
        acc[dns] = xAxisData.map(x => ({ time: x, ctrlRate: null })); // 为每个时间点初始化空数据
      }
      const index = acc[dns].findIndex(item => item.time === time);
      if (index !== -1) {
        acc[dns][index].ctrlRate = ctrlRate; // 更新控制率
      }
      return acc;
    }, {});

    // 构建ECharts series数据
    const series = Object.keys(seriesData).map(dns => ({
      name: dns,
      type: 'line',
      data: seriesData[dns].map(item => item.ctrlRate), // 现在这里的数据已经与x轴对齐
    }));

    return {
      tooltip: {
        trigger: 'axis',
        formatter: (params) => {
          let result = `${params[0].axisValue}<br/>`;
          params.forEach(param => {
            result += `${param.seriesName}: ${param.value}<br/>`;
          });
          return result;
        }
      },
      legend: {
        data: Object.keys(seriesData),
      },
      xAxis: {
        type: 'category',
        data: xAxisData,
        axisLabel: {
          formatter: function (value) {
            return value.split('T')[1]; // 如果你的时间数据包含日期和时间，这里只显示时间
          }
        }
      },
      yAxis: {
        type: 'value',
        name: 'Control Rate',
        axisLabel: {
          formatter: '{value}'
        }
      },
      series: series
    };
  };



  const fullEscapeColumns = [
    {
      title: '任务',
      dataIndex: 'task',
      key: 'task',
    },
    {
      title: '节点',
      dataIndex: 'node',
      key: 'node',
    },
    {
      title: '域名',
      dataIndex: 'domain',
      key: 'domain',
    },
    {
      title: 'DNS服务器ip',
      dataIndex: 'dns',
      key: 'dns',
    },
    {
      title: '解析IP结果',
      dataIndex: 'ip',
      key: 'ip',
    },
  ];

  const fullControlColumns = [
    {
      title: '任务',
      dataIndex: 'task',
      key: 'task',
    },
    {
      title: '节点',
      dataIndex: 'node',
      key: 'node',
    },
    {
      title: '域名',
      dataIndex: 'domain',
      key: 'domain',
    },
    {
      title: 'DNS服务器ip',
      dataIndex: 'dns',
      key: 'dns',
    },
  ];

  const reErrorColumns = [
    {
      title: '任务',
      dataIndex: 'task',
      key: 'task',
    },
    {
      title: '节点',
      dataIndex: 'node',
      key: 'node',
    },
    {
      title: '域名',
      dataIndex: 'domain',
      key: 'domain',
    },
    {
      title: 'DNS服务器ip',
      dataIndex: 'dns',
      key: 'dns',
    },
    {
      title: '类型',
      dataIndex: 'type',
      key: 'type',
    },
  ];

  return (
    <div>
      <Row gutter={[16, 24]}>
        <Col span={24}>
          <Title className="h-title" level={3}>历史任务数据</Title>
          {loadingStates.taskCtrlRate ? (
            <Spin size="large" />
          ) : (
            <>
              <Table
                className="domainResult-table"
                columns={taskCtrlDataColumns}
                dataSource={taskCtrlRateData.data}
                pagination={{
                  current: currentPageTaskCtrl,
                  pageSize: pageSizeTaskCtrl,
                  total: taskCtrlRateData.total,
                  onChange: (page, pageSize) => {
                    setCurrentPageTaskCtrl(page);
                    setPageSizeTaskCtrl(pageSize);
                  },
                }}
              />
              <ReactEcharts className="domainResult-chart" option={TaskCtrlOption()} style={{ height: '400px', marginTop: '20px' }} />
              <TaskDataControlComponent/>
            </>
          )}
        </Col>
        <Col span={24}>
          <Title className="h-title" level={3}>节点控制率</Title>
          {loadingStates.nodeCtrlRate ? (
            <Spin size="large" />
          ) : (
            <>
              <Table
                className="domainResult-table"
                columns={nodeCtrlDataColumns}
                dataSource={nodeCtrlRateData.data}
                pagination={{
                  current: currentPageNodeCtrl,
                  pageSize: pageSizeNodeCtrl,
                  total: nodeCtrlRateData.total,
                  onChange: (page, pageSize) => {
                    setCurrentPageNodeCtrl(page);
                    setPageSizeNodeCtrl(pageSize);
                  },
                }}
              />
              <ReactEcharts className="domainResult-chart" option={NodeCtrlOption()} style={{ height: '400px', marginTop: '20px' }} />
            </>
          )}
        </Col>
        <Col span={24}>
          <Title className="h-title" level={3}>域名控制率</Title>
          {loadingStates.domainCtrlRate ? (
            <Spin size="large" />
          ) : (
            <>
              <Table
                className="domainResult-table"
                columns={domainCtrlDataColumns}
                dataSource={domainCtrlRateData.data}
                pagination={{
                  current: currentPageDomainCtrl,
                  pageSize: pageSizeDomainCtrl,
                  total: domainCtrlRateData.total,
                  onChange: (page, pageSize) => {
                    setCurrentPageDomainCtrl(page);
                    setPageSizeDomainCtrl(pageSize);
                  },
                }}
              />
              <ReactEcharts className="domainResult-chart" option={DomainCtrlOption()} style={{ height: '400px', marginTop: '20px' }} />
            </>
          )}
        </Col>
        <Col span={24}>
          <Title className="h-title" level={3}>DNS服务器控制率</Title>
          {loadingStates.dnsCtrlRate ? (
            <Spin size="large" />
          ) : (
            <>
              <Table
                className="domainResult-table"
                columns={dnsCtrlDataColumns}
                dataSource={dnsCtrlRateData.data}
                pagination={{
                  current: currentPageDnsCtrl,
                  pageSize: pageSizeDnsCtrl,
                  total: dnsCtrlRateData.total,
                  onChange: (page, pageSize) => {
                    setCurrentPageDnsCtrl(page);
                    setPageSizeDnsCtrl(pageSize);
                  },
                }}
              />
              <ReactEcharts className="domainResult-chart" option={DnsCtrlOption()} style={{ height: '400px', marginTop: '20px' }} />
            </>
          )}
        </Col>
        <Col span={24}>
          <Title className="h-title" level={3}>完全逃逸数据</Title>
          {loadingStates.fullEscape ? (
            <Spin size="large" />
          ) : (
            <Table
              className="domainResult-table"
              columns={fullEscapeColumns}
              dataSource={fullEscapeData.data}
              pagination={{
                current: currentPageFullEscape,
                pageSize: pageSizeFullEscape,
                total: fullEscapeData.total,
                onChange: (page, pageSize) => {
                  setCurrentPageFullEscape(page);
                  setPageSizeFullEscape(pageSize);
                },
              }}
            />
          )}
        </Col>
        <Col span={24}>
          <Title className="h-title" level={3}>完全控制数据</Title>
          {loadingStates.fullControl ? (
            <Spin size="large" />
          ) : (
            <Table
              className="domainResult-table"
              columns={fullControlColumns}
              dataSource={fullControlData.data}
              pagination={{
                current: currentPageFullControl,
                pageSize: pageSizeFullControl,
                total: fullControlData.total,
                onChange: (page, pageSize) => {
                  setCurrentPageFullControl(page);
                  setPageSizeFullControl(pageSize);
                },
              }}
            />
          )}
        </Col>
        <Col span={24}>
          <Title className="h-title" level={3}>故障数据</Title>
          {loadingStates.reError ? (
            <Spin size="large" />
          ) : (
            <Table
              className="domainResult-table"
              columns={reErrorColumns}
              dataSource={reErrorData.data}
              pagination={{
                current: currentPageReError,
                pageSize: pageSizeReError,
                total: reErrorData.total,
                onChange: (page, pageSize) => {
                  setCurrentPageReError(page);
                  setPageSizeReError(pageSize);
                },
              }}
            />
          )}
        </Col>
      </Row>
    </div>
  );

};

export default DomainResultComponent;
