import React from 'react';
import { Collapse } from 'antd';
import './TableCellWithFormattedQueryPacket.css'; // 引入样式文件



const { Panel } = Collapse;

const TableCellWithFormattedQueryPacket = ({ queryPacket }) => {

  const renderValue = (value) => {
    if (typeof value === 'object') {
      // 如果值是对象或数组，递归渲染
      return <div style={{ marginLeft: '20px' }}>{renderFormattedJson(value)}</div>;
    }
    // 对于普通值，直接返回
    return <span>{value}</span>;
  };

  const renderFormattedJson = (packet) => {
    return Object.entries(packet).map(([key, value]) => {
      // 对于 'Src_IP' 特殊处理，标红显示
      const displayValue = key === 'src_ip' ? <span style={{ color: 'red' }}>{renderValue(value)}</span> : renderValue(value);
      return <div key={key}><span>{key}: </span>{displayValue}</div>;
    });
  };

  // 确保 queryPacket 是一个对象
  const packetObj = typeof queryPacket === 'string' ? JSON.parse(queryPacket) : queryPacket;

  return (
    <Collapse className="collapse-container" accordion>
      <Panel className="collapse-panel" header="展开查看详情" key="1">
        <pre><code className="collapse-content">{renderFormattedJson(packetObj)}</code></pre>
      </Panel>
    </Collapse>
  );
};

export default TableCellWithFormattedQueryPacket;
