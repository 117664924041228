import React from 'react';
import { Table, Tag } from 'antd';

const DomainStatusComponent = () => {
  const fakeData = [
    { dnsServer: '8.8.8.8', domainName: 'example.com', responseStatus: 'normal', probePoint: 'Point A' },
    { dnsServer: '1.1.1.1', domainName: 'example.org', responseStatus: 'escape', probePoint: 'Point B' },
    { dnsServer: '208.67.222.222', domainName: 'example.net', responseStatus: 'normal', probePoint: 'Point C' },
    // Add more fake data as needed
  ];

  const columns = [
    {
      title: 'DNS Server',
      dataIndex: 'dnsServer',
      key: 'dnsServer',
    },
    {
      title: 'Domain Name',
      dataIndex: 'domainName',
      key: 'domainName',
    },
    {
      title: 'Response Status',
      dataIndex: 'responseStatus',
      key: 'responseStatus',
      render: (status) => (
        <Tag color={status === 'escape' ? 'red' : 'green'}>
          {status}
        </Tag>
      ),
    },
    {
      title: 'Probe Point',
      dataIndex: 'probePoint',
      key: 'probePoint',
    },
  ];

  return (
    <div>
      <Table dataSource={fakeData} columns={columns} />
    </div>
  );
};

export default DomainStatusComponent;
