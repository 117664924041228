import React, { useState, useEffect } from 'react';
import { List, Button, Card, Row, Col, Space, Pagination, Select } from 'antd';
import axios from 'axios';
import './DetectionData.css';

const { Option } = Select;

const DetectionData = () => {
  const [files, setFiles] = useState([]);
  const [totalFiles, setTotalFiles] = useState(0); // 新增状态存储总文件数量
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortOrder, setSortOrder] = useState('desc');

  useEffect(() => {
    fetchFiles();
  }, [currentPage, pageSize, sortOrder]);

  const fetchFiles = async () => {
    try {
      const response = await axios.get(`http://82.156.9.34:5000/list_files`);
      let sortedFiles = response.data.sort((a, b) => {
        return sortOrder === 'desc' ? b.localeCompare(a) : a.localeCompare(b);
      });
      setTotalFiles(sortedFiles.length); // 在排序之后设置总文件数量
      const startIndex = (currentPage - 1) * pageSize;
      sortedFiles = sortedFiles.slice(startIndex, startIndex + pageSize);
      setFiles(sortedFiles);
    } catch (error) {
      console.error('Error fetching file list:', error);
    }
  };

  const formatDate = (filename) => {
    const pattern = /(\d{4})(\d{2})(\d{2})\.zip/;
    const match = filename.match(pattern);
    if (match) {
      return `${match[1]}-${match[2]}-${match[3]}`;
    }
    return filename;
  };

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (current, size) => {
    setPageSize(size);
  };

  const handleSortChange = (value) => {
    setSortOrder(value);
  };

  return (
    <Card title="可下载文件" className="card-title">
      <div style={{ marginBottom: '10px' }} className="sort-select">
        <Select defaultValue="desc" onChange={handleSortChange}>
          <Option value="desc">最新的</Option>
          <Option value="asc">最早的</Option>
        </Select>
      </div>
      <List
        bordered
        dataSource={files}
        renderItem={(file) => (
          <List.Item className="list-item">
            <Row justify="space-between" align="middle" style={{ width: '100%' }}>
              <Col className="file-name">{formatDate(file)}</Col>
              <Col>
                <Space>
                  <Button type="primary" href={`http://82.156.9.34:5000/download_file/${file}`} download className="download-button">
                    Download
                  </Button>
                </Space>
              </Col>
            </Row>
          </List.Item>
        )}
      />
      <Pagination
        current={currentPage}
        pageSize={pageSize}
        total={totalFiles} // 修改为使用总文件数量
        onChange={handlePageChange}
        showSizeChanger
        onShowSizeChange={handlePageSizeChange}
        showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
      />
    </Card>
  );
};

export default DetectionData;
