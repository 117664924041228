import React, { useState } from "react";
import axios from "axios";
import {
  Layout,
  Menu,
  Button,
  Input,
} from "antd";
import {
  UserOutlined,
  LockOutlined,
  HomeOutlined,
  SearchOutlined,
  EnvironmentOutlined, SettingOutlined,
} from "@ant-design/icons";

import MapComponent from './components/MapComponent';
import SearchComponent from './components/SearchComponent';
import DashBoardComponent from "./components/DashBoardComponent";
import "./App.css";
import './components/MapComponent.css'; // 确保创建并引入了相应的CSS文件

import TestComponent from "./components/TestComponent";
import WorldMapComponent from "./components/WorldMapComponent";
import DomainComponent from "./components/domain/DomainComponent";
import DetectionComponent from "./components/detection/DetectionComponent";
import ClassificationComponent from "./components/classification/ClassificationComponent";
import DomainResultComponent from "./components/domain/DomainResultComponent";
import config from "./config.js"
import DetectionData from "./components/data/DetectionData";

const { Header, Content, Footer } = Layout;

function App() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [error, setError] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);
  const [selectedTab, setSelectedTab] = useState("map");

  const [theme, setTheme] = useState('dark');


  const handleLogin = async () => {
    try {
      const formData = new FormData();
      formData.append("username", username);
      formData.append("password", password);

      const response = await axios.post(
        `${config.globalUrl}/login/`,
        formData,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      const { access_token } = response.data;

      // 设置访问令牌
      setLoggedIn(true);
      setAccessToken(access_token);
      setError("");

      // 设置 Axios 的全局配置
      axios.defaults.baseURL = `${config.globalUrl}`; // 设置统一的基础路径

      axios.defaults.headers.common["Authorization"] = `Bearer ${access_token}`; // 设置统一的 Authorization 头部
    } catch (err) {
      console.error("Error:", err);
      setError("登录失败，请检查您的凭据。");
    }
  };

  const handleKeyPress = (e) => {
    // Check if the pressed key is Enter (key code 13)
    if (e.key === 'Enter') {
      handleLogin();
    }
  };
  const handleLogout = () => {
    setLoggedIn(false);
    setAccessToken("");
  };

  const renderSelectedTab = () => {
    switch (selectedTab) {
      case "dashboard":
        return <DashBoardComponent/>
      case "search":
        return <SearchComponent />;
      case "data":
        return <DetectionData />;
      case "map":
        return <MapComponent />;
      case "world":
        return <WorldMapComponent />;
      case "domain":
        return <DomainComponent />;
      case "monitor":
        return <DomainResultComponent />;
      case "detection":
        return <DetectionComponent/>;
      case "classification":
        return <ClassificationComponent />;
      case "test":
        return <TestComponent />;
      default:
        return null;
    }
  };

  const breadcrumbRoutes = [
    {
      path: "/",
      breadcrumbName: "Home",
      icon: <HomeOutlined />,
    },
    {
      path: `/${selectedTab}`,
      breadcrumbName: selectedTab.charAt(0).toUpperCase() + selectedTab.slice(1),
    },
  ];


  return (
   //<Watermark content="WIZEYE">
    <Layout className="layout" style={{ minHeight: '100vh' }}>

      <Header>
        {loggedIn && (
          <Menu
            theme={theme}
            mode="horizontal"
            defaultSelectedKeys={["1"]}
            items={[
              {
                key: "1",
                icon: <UserOutlined />,
                label: "退出",
                onClick: handleLogout,
              },
              {
                key: "2",
                icon: <HomeOutlined />,
                label: "DNS统计数据",
                onClick: () => setSelectedTab("dashboard"),
              },
              {
                key: "3",
                icon: <EnvironmentOutlined/>,
                label: "DNS探测",
                onClick: () => setSelectedTab("detection"),
              },
              {
                key: "4",
                icon: <SearchOutlined />,
                label: "DNS服务器查询",
                onClick: () => setSelectedTab("search"),
              },
              {
                key: "5",
                icon: <HomeOutlined />,
                label: "数据下载",
                onClick: () => setSelectedTab("data"),
              },
              {
                key: "6",
                icon: <EnvironmentOutlined />,
                label: "DNS服务器分布",
                onClick: () => setSelectedTab("map"),
              },
              {
                key: "7",
                icon: <HomeOutlined />,
                label: "全球DNS服务器分布",
                onClick: () => setSelectedTab("world"),
              },
              {
                key: "8",
                icon: <EnvironmentOutlined />,
                label: "DNS服务器分级分类",
                onClick: () => setSelectedTab("classification"),
              },
              {
                key: "9",
                icon: <EnvironmentOutlined />,
                label: "重点域名监测任务配置",
                onClick: ()  => setSelectedTab("domain"),
              },
              {
                key: "10",
                icon: <EnvironmentOutlined />,
                label: "重点域名监测结果",
                onClick: () => setSelectedTab("monitor"),
              },
              {
                key: "11",
                icon: <HomeOutlined />,
                label: "测试",
                onClick: () => setSelectedTab("test"),
              },
            ]}
          />
        )}

      </Header>
        <Content style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
            {!loggedIn ? (
              <div className="login-container" style={{ maxWidth: '400px', width: '100%', padding: '20px', border: '1px solid #f0f0f0', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', borderRadius: '5px' }}>
                <form>
                  <Input
                    prefix={<UserOutlined />}
                    placeholder="用户名"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    style={{ marginBottom: '10px' }}
                    autoComplete="user" // Add autocomplete attribute
                  />

                  <Input.Password
                    prefix={<LockOutlined />}
                    placeholder="密码"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyPress={handleKeyPress}
                    style={{ marginBottom: '10px' }}
                    autoComplete="user" // Add autocomplete attribute
                  />

                  <Button type="primary" onClick={handleLogin} block>
                    登录
                  </Button>
                </form>
                {error && <p className="error-message">{error}</p>}
              </div>
            ) : (
            <div className="" style={{ width: '100%' }}>
              {renderSelectedTab()}
            </div>
          )}
        </Content>

    </Layout>
   //</Watermark>
  );
}

export default App;
