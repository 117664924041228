// import React, { useState } from 'react';
// import { Upload, Button, message, Table } from 'antd';
// import { UploadOutlined } from '@ant-design/icons';
// import ExcelJS from 'exceljs';
//
// const UploadDomainComponent = ({ setDomainData }) => {
//   const [dataSource, setDataSource] = useState([]);
//   const [columns, setColumns] = useState([]);
//
//   const handleUpload = async (file) => {
//     try {
//       const workbook = new ExcelJS.Workbook();
//       await workbook.xlsx.load(file);
//
//       const sheet = workbook.getWorksheet(1);
//
//       const columns = sheet.getRow(1).values.map((col) => ({
//         title: col,
//         dataIndex: col,
//         key: col,
//       }));
//
//       const rowData = sheet.getRows(2, sheet.rowCount).map((row, index) => ({
//         key: index.toString(),
//         ...row.values.reduce((acc, value, colIndex) => {
//           acc[columns[colIndex].dataIndex] = value;
//           return acc;
//         }, {}),
//       }));
//
//       setColumns(columns);
//       setDataSource(rowData);
//
//       // Pass the data to the parent component
//       setDomainData(rowData);
//     } catch (error) {
//       console.error('Error parsing Excel file', error);
//       message.error('Error parsing Excel file');
//     }
//   };
//
//   const customRequest = ({ file, onSuccess }) => {
//     setTimeout(() => {
//       onSuccess('ok');
//       handleUpload(file);
//     }, 0);
//   };
//
//   return (
//     <div>
//       <Upload customRequest={customRequest} showUploadList={false}>
//         <Button icon={<UploadOutlined />}>上传重点域名文件(xlsx格式)</Button>
//       </Upload>
//       <Table dataSource={dataSource} columns={columns} />
//     </div>
//   );
// };
//
// export default UploadDomainComponent;

import React, { useState } from 'react';
import { Upload, Button, message, Table } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import ExcelJS from 'exceljs';
import { parse } from 'papaparse';

const UploadDomainComponent = ({ setDomainData }) => {
  const [dataSource, setDataSource] = useState([]);
  const [columns, setColumns] = useState([]);

  const handleXLSXUpload = async (file) => {
    try {
      const workbook = new ExcelJS.Workbook();
      await workbook.xlsx.load(file);

      const sheet = workbook.getWorksheet(1);
      const columns = sheet.getRow(1).values.map((col, index) => ({
        title: col,
        dataIndex: col,
        key: index.toString(),
      }));

      const rowData = sheet.getRows(2, sheet.rowCount).map((row, index) => ({
        key: index.toString(),
        ...row.values.reduce((acc, value, colIndex) => {
          if (columns[colIndex]) {
            acc[columns[colIndex].dataIndex] = value;
          }
          return acc;
        }, {}),
      }));

      setColumns(columns);
      setDataSource(rowData);

      // Pass the data to the parent component
      setDomainData(rowData);
    } catch (error) {
      console.error('Error parsing Excel file', error);
      message.error('Error parsing Excel file');
    }
  };

  const handleCSVUpload = (file) => {
    const reader = new FileReader();
    reader.onload = () => {
      const results = parse(reader.result, { header: true });
      const columns = Object.keys(results.data[0]).map((key, index) => ({
        title: key,
        dataIndex: key,
        key: index.toString(),
      }));

      const rowData = results.data.map((item, index) => ({
        key: index.toString(),
        ...item
      }));

      setColumns(columns);
      setDataSource(rowData);
      setDomainData(rowData);
    };
    reader.onerror = () => {
      console.error('Error reading the CSV file', reader.error);
      message.error('Error reading CSV file');
    };
    reader.readAsText(file);
  };

  const customRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      const fileExt = file.name.split('.').pop().toLowerCase();
      if (fileExt === 'xlsx') {
        handleXLSXUpload(file);
      } else if (fileExt === 'csv') {
        handleCSVUpload(file);
      } else {
        message.error('Unsupported file type');
      }
      onSuccess('ok');
    }, 0);
  };

  return (
    <div>
      <Upload customRequest={customRequest} showUploadList={false} accept=".xlsx,.csv">
        <Button icon={<UploadOutlined />}>上传重点域名文件(xlsx/csv格式)</Button>
      </Upload>
      <Table dataSource={dataSource} columns={columns} />
    </div>
  );
};

export default UploadDomainComponent;
