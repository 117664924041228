import React, {useState} from 'react';
import { Form, Input, Button, message, Row, Col } from 'antd';
import {MinusOutlined, PlusOutlined, UploadOutlined} from '@ant-design/icons';
import { Badge, Descriptions } from 'antd';
import { Flex, Progress } from 'antd';

import './DetectionComponent.css'

const DetectionComponent = () => {
  const [form] = Form.useForm();
  const [percent, setPercent] = useState(0);
  const increase = () => {
    setPercent((prevPercent) => {
      const newPercent = prevPercent + 10;
      if (newPercent > 100) {
        return 100;
      }
      return newPercent;
    });
  };
  const decline = () => {
    setPercent((prevPercent) => {
      const newPercent = prevPercent - 10;
      if (newPercent < 0) {
        return 0;
      }
      return newPercent;
    });
  };
  const onFinish = (values) => {
    const { ipAddressCIDR } = values;

    // 在这里可以发送 ipAddressCIDR 到后端
    // 例如 fetch:
    // fetch('/your-backend-endpoint', {
    //   method: 'POST',
    //   body: JSON.stringify({ ipAddressCIDR }),
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    // })
    // .then(response => response.json())
    // .then(data => console.log(data))
    // .catch(error => console.error('Error:', error));

    // 现在，让我们先显示一条消息
    message.info(`开始检测 IP: ${ipAddressCIDR}`);
  };

  const items = [
        {
        key: '1',
        label: 'Product',
        children: 'Cloud Database',
        },
        {
        key: '2',
        label: 'Billing Mode',
        children: 'Prepaid',
        },
        {
        key: '3',
        label: 'Automatic Renewal',
        children: 'YES',
        },
        {
        key: '4',
        label: 'Order time',
        children: '2018-04-24 18:00:00',
        },
        {
        key: '5',
        label: 'Usage Time',
        children: '2019-04-24 18:00:00',
        span: 2,
        },
        {
        key: '6',
        label: 'Status',
        children: <Badge status="processing" text="Running" />,
        span: 3,
        },
        {
        key: '7',
        label: 'Negotiated Amount',
        children: '$80.00',
        },
        {
        key: '8',
        label: 'Discount',
        children: '$20.00',
        },
        {
        key: '9',
        label: 'Official Receipts',
        children: '$60.00',
        },
        {
        key: '10',
        label: 'Config Info',
        children: (
          <>
            Data disk type: MongoDB
            <br />
            Database version: 3.4
            <br />
            Package: dds.mongo.mid
            <br />
            Storage space: 10 GB
            <br />
            Replication factor: 3
            <br />
            Region: East China 1
            <br />
          </>
        ),
        },
  ];


  return (
    <Form form={form} onFinish={onFinish}>
      <Flex gap="small" vertical className="custom-progress">
        <Progress percent={30} />
        <Progress percent={50} status="active" />
        <Progress percent={70} status="exception" />
        <Progress percent={100} />
        <Progress percent={50} showInfo={false} />
      </Flex>
      <Flex gap="small" wrap="wrap" className="custom-circle-progress">
        <Progress type="circle" percent={75} format={(percent) => `${percent} Days`} />
        <Progress type="circle" percent={100} format={() => 'Done'} />
      </Flex>
      <Flex vertical gap="small">
        <Flex vertical gap="small">
          <Progress percent={percent} type="line" />
          <Progress percent={percent} type="circle" />
        </Flex>
        <Button.Group>
          <Button onClick={decline} icon={<MinusOutlined />} className="custom-button" />
          <Button onClick={increase} icon={<PlusOutlined />} className="custom-button" />
        </Button.Group>
      </Flex>
      <Descriptions className="custom-descriptions-wrapper" title="User Info" bordered items={items} />
      <Form.Item
        name="ipAddressCIDR"
        label={
          <span style={{ color: 'var(--text-color)' }}>
            IP 地址 (CIDR 格式)
          </span>
        }
        rules={[
          {
            required: true,
            message: '请输入CIDR格式的IP地址',
          },
        ]}
      >
        <Input placeholder="例如: 192.168.1.0/24" />
      </Form.Item>

      <Form.Item>
        <Button type="primary" icon={<UploadOutlined />} htmlType="submit">
          开始检测
        </Button>
      </Form.Item>
    </Form>
  );
};

export default DetectionComponent;
