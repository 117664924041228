import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';
import testJson from './test.json';
import TaskDataControlComponent from "./domain/TaskDataControlComponent"; // Importing the JSON file directly

const TestComponent = () => {
  const chartRef = useRef(null);

  useEffect(() => {
    const myChart = echarts.init(chartRef.current);

    // Process data as needed
    testJson.nodes.forEach(function (node) {
      node.label = {
        show: node.symbolSize > 30
      };
    });

    const option = {
      title: {
        text: '域名关联绘制',
        subtext: 'Default layout',
        top: 'bottom',
        left: 'right'
      },
      tooltip: {},
      legend: [
        {
          data: testJson.categories.map(function (a) {
            return a.name;
          })
        }
      ],
      animationDuration: 1500,
      animationEasingUpdate: 'quinticInOut',
      series: [
        {
          name: 'Les Miserables',
          type: 'graph',
          layout: 'none',
          data: testJson.nodes,
          links: testJson.links,
          categories: testJson.categories,
          roam: true,
          label: {
            position: 'right',
            formatter: '{b}'
          },
          lineStyle: {
            color: 'source',
            curveness: 0.3
          },
          emphasis: {
            focus: 'adjacency',
            lineStyle: {
              width: 10
            }
          }
        }
      ]
    };

    myChart.setOption(option);

    return () => {
      // Cleanup function
      myChart.dispose();
    };
  }, []);

  return(
      <div>
        <TaskDataControlComponent/>
        <div ref={chartRef} style={{ width: '100%', height: '1200px' }}></div>
      </div>
  );
};

export default TestComponent;
