import React, { useState } from 'react';
import { Input, Button, Card, Spin, Alert, Table, List, Col, Row, message, Collapse } from 'antd';
import axios from 'axios';
import "./SearchComponent.css";
import {ClearOutlined, SearchOutlined} from "@ant-design/icons";
import config from "../config";
import TableCellWithFormattedQueryPacket from "./TableCellWithFormattedQueryPacket";
import { Timeline } from 'antd';

const { Panel } = Collapse;

const SearchComponent = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [result, setResult] = useState(null);
  const [response, setResponse] = useState([]);
  const [request, setRequest] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [history, setHistory] = useState([]);
  const [tableData, setTableData] = useState([]);

  const [searchIP, setSearchIP] = useState('');
  const [iptableData, setiptableData] = useState([]); // Ensure it's initialized as an array, not null
  const responseColumns = [
    {
      title: '源IP',
      dataIndex: 'src_ip',
      key: 'src_ip',
    },
    {
      title: '操作',
      dataIndex: 'Type',
      key: 'Type',
    },
    {
      title: '目的IP',
      dataIndex: 'dst_ip', // 根据新数据结构修改
      key: 'dst_ip', // 根据新数据结构修改
    },
  ];
  const requestColumns = [
    {
      title: '发送查询的DNS服务器IP',
      dataIndex: 'dst_ip', // 根据新数据结构修改
      key: 'dst_ip', // 根据新数据结构修改
    },
    {
      title: '操作',
      dataIndex: 'Type',
      key: 'Type',
    },
    {
      title: '向权威服务器请求的IP',
      dataIndex: 'src_ip', // 根据新数据结构修改
      key: 'src_ip', // 根据新数据结构修改
    },
  ];
  // 主表格列配置
  const columns = [
    { title: 'IP地址', dataIndex: 'target', key: 'target' },
    { title: '支持TCP', dataIndex: 'tcp_support', key: 'tcp_support', render: tcp_support => tcp_support ? '支持' : '不支持' },
    { title: '支持TLS', dataIndex: 'tls_support', key: 'tls_support', render: tls_support => tls_support ? '支持' : '不支持' },
    { title: '支持HTTPS', dataIndex: 'https_support', key: 'https_support', render: https_support => https_support ? '支持' : '不支持' },
    { title: '支持EDNS', dataIndex: 'edns_support', key: 'edns_support', render: edns_support => edns_support ? '支持' : '不支持' },
    { title: '支持DNSSEC', dataIndex: 'dnssec_support', key: 'dnssec_support', render: dnssec_support => dnssec_support ? '支持' : '不支持' },
  ];
  // 展示详细信息的组件
  const DetailsPanel = ({ record }) => {
    const dnsKeys = Object.keys(record.dns_message);

    return (
      <Collapse bordered={false}>
        {dnsKeys.map((key) => (
          <Panel header={`${key.toUpperCase()} DNS详情`} key={key}>
            <pre>{record.dns_message[key]}</pre>
          </Panel>
        ))}
        <Panel header="地理位置信息" key="location">
          <List
            dataSource={Object.entries(record.location)}
            renderItem={([key, value]) => (
              <List.Item>
                <strong>{key}:</strong> {value}
              </List.Item>
            )}
          />
        </Panel>
      </Collapse>
    );
  };

  const handleSearch = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.get(`${config.globalUrl}/get_dns_packet/${searchTerm}`, {
        timeout: 5000, // 设置超时时间为5000毫秒
      });
      const data = response.data;
      console.log(data)

      if (data && data.request && data.response) {
        // 如果请求成功并且存在请求和响应数据

        // 显示成功消息
        message.success('搜索成功');

        // 处理响应数据
        const responseData = data.response.map((response, index) => ({
          order: response.order,
          src_ip: response.src_ip,
          src_port: response.src_port,
          Type: response.Type,
          dst_ip: response.dst_ip,
          dst_port: response.dst_port,
          Packet: response.Packet
        }));
        setResponse(responseData)
        // 处理请求数据
        const requestData = data.request.map((request, index) => ({
          order: request.order,
          src_ip: request.src_ip,
          src_port: request.src_port,
          Type: request.Type,
          dst_ip: request.dst_ip,
          dst_port: request.dst_port,
          Packet: request.Packet
        }));
        setRequest(requestData)

        // 合并请求和响应数据
        const combinedData = [...responseData, ...requestData];
        // Sort combinedData by 'order'
        combinedData.sort((a, b) => a.order - b.order);

        setResult(combinedData)

        // 设置表格数据
        const newTableData = combinedData.map((item, index) => ({
          key: index.toString(),
          order: item.order,
          src_ip: item.src_ip,
          dst_ip: item.dst_ip,
          Type: item.Type,
          query_packet: item.Packet,
        }));

        setTableData(newTableData);
        // 更新搜索历史记录
        setHistory([...history, { searchTerm, result: data.result, response: responseData }]);
      } else {
        // 数据不完整，显示错误消息
        message.error('搜索失败，数据格式不正确');
      }
    } catch (error) {
      // 处理请求失败的情况
      if (error.code === 'ECONNABORTED') {
        console.error('请求超时', error);
        message.error('搜索失败，请求超时');
        setError('搜索失败，请求超时');
      } else {
        console.error('请求失败', error);
        message.error('搜索失败，请求失败');
        setError('搜索失败，请求失败');
      }
    } finally {
      setLoading(false);
    }
  };
  const handleClear = () => {
    setSearchTerm('');
    setResult(null);
    setResponse([]);
    setRequest([]);
    setTableData([]);
    setError(null);
  };


  const handleSearchIP = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.get(`http://127.0.0.1:8080/search_dns?ip=${searchIP}`, {
        timeout: 5000, // Set the timeout to 5000 milliseconds
      });
      const data = response.data;
      console.log(data);

      if (data && !data.error) {
        // If the data is an object and doesn't contain an error field
        setiptableData(Array.isArray(data) ? data : [data]); // Wrap the object into an array if it's not already an array
        message.success('搜索成功');
      } else {
        // Handle cases where the data includes an error field or is not in expected format
        message.error(data.error || '搜索失败，数据格式不正确');
        setiptableData([]); // Set as empty array as a fallback
        setError(data.error || '搜索失败，数据格式不正确');
      }
    } catch (error) {
      if (error.code === 'ECONNABORTED') {
        console.error('请求超时', error);
        message.error('搜索失败，请求超时');
        setError('搜索失败，请求超时');
      } else {
        console.error('请求失败', error);
        message.error('搜索失败，请求失败');
        setError('搜索失败，请求失败');
      }
    } finally {
      setLoading(false);
    }
  };



  const handleClearIP = () => {
    setSearchTerm('');
    setResult(null);
    setResponse([]);
    setRequest([]);
    setTableData([]);
    setError(null);
  };

  return (
    <div className="search-component">
      <Row gutter={16}>
        <Col span={18}>
          <Input
            className="search-input"
            placeholder="捕获实时数据报文, 请输入DNS服务器的IP"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Col>
        <Col span={3}>
          <Button className="search-button" type="primary" icon={<SearchOutlined />} onClick={handleSearch} block>
            搜索
          </Button>
        </Col>
        <Col span={3}>
          <Button className="search-button" icon={<ClearOutlined />} onClick={handleClear} block>
            清除
          </Button>
        </Col>
      </Row>

      {loading && <Spin size="large" style={{ display: 'block', marginTop: '20px' }} />}

      {error && <Alert message={error} type="error" showIcon style={{ marginTop: '20px' }} />}

      {!loading && result && result.length > 0 && (
        <Card className="search-card" title="DNS 查询时间线" style={{ marginTop: '20px' }}>
          <Timeline>
            {result.map((item, index) => (
              <Timeline.Item key={index}>
                {item.src_ip === '172.19.57.221' ? '本服务器': item.src_ip} 向 {item.dst_ip === '172.19.57.221' ? '本服务器':item.dst_ip} 发送 {item.Type === 'Request' ? '查询' : '响应'}
              </Timeline.Item>
            ))}
          </Timeline>
        </Card>
      )}

      {request.length > 0 && (
        <Card className="search-card" title="请求" style={{ marginTop: '20px' }}>
          <Table className="search-table" columns={requestColumns} dataSource={request} pagination={false} />
        </Card>
      )}
      {response.length > 0 && (
        <Card className="search-card" title="响应" style={{ marginTop: '20px' }}>
          <Table className="search-table" columns={responseColumns} dataSource={response} pagination={false} />
        </Card>
      )}

      {tableData.length > 0 && (
        <Card className="search-card" title="权威侧抓包数据" style={{ marginTop: '20px' }}>
          {tableData.map((data, index) => (
            <TableCellWithFormattedQueryPacket key={index} queryPacket={data} />
          ))}
        </Card>
      )}

      <Row gutter={16}>
        <Col span={18}>
          <Input
            className="search-input"
            placeholder="搜索探测记录, 请输入网段，例如114.114.114.0/24"
            value={searchIP}
            onChange={(e) => setSearchIP(e.target.value)}
          />
        </Col>
        <Col span={3}>
          <Button className="search-button" type="primary" icon={<SearchOutlined />} onClick={handleSearchIP} block>
            搜索
          </Button>
        </Col>
        <Col span={3}>
          <Button className="search-button" icon={<ClearOutlined />} onClick={handleClearIP} block>
            清除
          </Button>
        </Col>
        <Col span={24}>
          <Table dataSource={iptableData} columns={columns} expandedRowRender={record => <DetailsPanel record={record} />} />
        </Col>
      </Row>

      {/*{history.length > 0 && (*/}
      {/*  <Card className="search-card" title="本次会话有效搜索历史记录" style={{ marginTop: '20px' }}>*/}
      {/*    <List*/}
      {/*      className="search-list"*/}
      {/*      dataSource={history}*/}
      {/*      renderItem={item => (*/}
      {/*        <List.Item>*/}
      {/*          <strong>{item.searchTerm}</strong>*/}
      {/*        </List.Item>*/}
      {/*      )}*/}
      {/*    />*/}
      {/*  </Card>*/}
      {/*)}*/}
    </div>
  );

};

export default SearchComponent;
