import React, { useEffect, useState } from 'react';
import { Form, Select, Button, Row, Col, Card, Statistic } from 'antd';
import { ArrowDownOutlined, ArrowUpOutlined, UploadOutlined } from '@ant-design/icons';
import { PieChart, Pie, Cell, Legend } from 'recharts';
import './ClassificationComponent.css';

// Import your custom components
import EncryptedDnsComponent from './EncryptedDnsComponent';
import FunctionComponent from './FunctionComponent';
import ProtocolComponent from './ProtocolComponent';
import ScaleComponent from './ScaleComponent';
import ServiceScopeComponent from './ServiceScopeComponent';

const { Option } = Select;

const ClassificationComponent = () => {
  const [form] = Form.useForm();
  const [data, setData] = useState({ scaleData: [], protocolData: [], functionData: [] });

  const generateFakeData = () => {
    return {
      scaleData: [
        { name: '大', value: Math.random() * 1000 },
        { name: '中', value: Math.random() * 1000 },
        { name: '小', value: Math.random() * 1000 },
      ],
      protocolData: [
        { name: 'DoQ', value: Math.random() * 1000 },
        { name: 'DoH', value: Math.random() * 1000 },
        { name: 'DoT', value: Math.random() * 1000 },
      ],
      functionData: [
        { name: '转发器', value: Math.random() * 1000 },
        { name: '递归解析器', value: Math.random() * 1000 },
      ],
    };
  };

  const colors = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

  useEffect(() => {
    setData(generateFakeData());
  }, []);

  const renderCustomizedLabel = ({
    cx, cy, midAngle, innerRadius, outerRadius, percent,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * Math.PI / 180);
    const y = cy + radius * Math.sin(-midAngle * Math.PI / 180);

    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  return (
    <div className="classification-container">
      <Row gutter={16} style={{ marginBottom: '20px' }}>
        <Col span={12}>
          <Card bordered={false} className="card">
            <Statistic
              title="DNS服务器数量"
              value={791392}
              precision={2}
              valueStyle={{ color: 'var(--primary-color)' }}
              prefix={<ArrowUpOutlined />}
              suffix=""
            />
          </Card>
        </Col>
        <Col span={12}>
          <Card bordered={false} className="card">
            <Statistic
              title="IdleSS"
              value={9.3}
              precision={2}
              valueStyle={{ color: 'var(--text-color)' }}
              prefix={<ArrowDownOutlined />}
              suffix="%"
            />
          </Card>
        </Col>
      </Row>
      <Row gutter={16}>
        {['scaleData', 'protocolData', 'functionData'].map((category, index) => (
          <Col span={8} key={index}>
            <Card title={category === 'scaleData' ? "服务器规模分布" : category === 'protocolData' ? "加密协议分布" : "功能分布"} className="card">
              <PieChart width={400} height={400}>
                <Pie
                  dataKey="value"
                  isAnimationActive={false}
                  data={data[category]}
                  cx={200}
                  cy={200}
                  outerRadius={80}
                  fill={colors[index]}
                  label={renderCustomizedLabel}
                >
                  {data[category].map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                  ))}
                </Pie>
                <Legend />
              </PieChart>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default ClassificationComponent;
