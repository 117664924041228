import React, { useState } from 'react';
import { Upload, Button, message } from 'antd';
import {CloudUploadOutlined, UploadOutlined} from '@ant-design/icons';
import UploadServerComponent from './UploadServerComponent';
import UploadDomainComponent from './UploadDomainComponent';
import DomainStatusComponent from './DomainStatusComponent';
import DomainServerConfigComponent from "./DomainServerConfigComponent";

const DomainComponent = () => {
  const [serverData, setServerData] = useState([]);
  const [domainData, setDomainData] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [resultData, setResultData] = useState(null);

  // 添加缺失的状态和更新函数
  const [config, setConfig] = useState({
    dataSource: [], // 你的数据源
    columns: [],    // 你的列配置
    scheduledTime: '08:00', // 预设的每天运行时间
  });

  // Define default columns
  const defaultColumns = [
    {
      title: 'Column 1',
      dataIndex: 'column1',
      key: 'column1',
    },
    {
      title: 'Column 2',
      dataIndex: 'column2',
      key: 'column2',
    },
    // Add more columns as needed
  ];

  const fakeResultData = [
    { dnsServer: '8.8.8.8', domainName: 'example.com', responseStatus: 'normal', probePoint: 'Point A' },
    { dnsServer: '1.1.1.1', domainName: 'example.org', responseStatus: 'escape', probePoint: 'Point B' },
    { dnsServer: '208.67.222.222', domainName: 'example.net', responseStatus: 'normal', probePoint: 'Point C' },
    // Add more fake data as needed
  ];

  const handleProcessData = async () => {
    try {
      // 将处理状态设置为 true
      setProcessing(true);

      // 逻辑：将服务器和域数据发送到后端并获取结果
      // 用实际的后端 API 调用替换以下行
      const result = await fetchDataFromBackend(serverData, domainData); // 实现此函数

      // 在设置状态之前检查结果是否不为 null 或 undefined
      if (result !== null && result !== undefined && result.count > 0) {
        // 设置结果数据
        setResultData(result);
        // 显示成功消息
        message.success('数据处理成功！');
      } else {
        // 处理结果为 null 或 undefined 的情况
        // 例如，您可能想设置默认值或显示错误消息
        setResultData(fakeResultData)
        console.error('从后端接收到空结果');
        message.error('从后端接收到空结果');
      }
    } catch (error) {
      console.error('处理数据时发生错误', error);
      message.error('处理数据时发生错误');
    } finally {
      // 在处理完成后将处理状态设置为 false
      setProcessing(false);
    }
  };

  const handleSaveConfig = (newConfig) => {
    // 更新状态中的配置
    setConfig(newConfig);
    // 这里可以执行其他保存配置的逻辑，比如发送到服务器
    console.log('保存的配置:', newConfig);
  };

  const fetchDataFromBackend = async (serverData, domainData) => {
    try {
      // Simulate backend processing delay
      await new Promise((resolve) => setTimeout(resolve, 2000));

      // Placeholder for your actual backend API call
      // Replace this with your real API call
      // For now, we'll just return some default data
      const response = await fetch('your-backend-api-url', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          serverData,
          domainData,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();

      return result || { /* Your default data here */ };
    } catch (error) {
      console.error('Error fetching data from backend:', error);

      // Return default data in case of an error
      return {};
    }
  };

  return (
    <div>
      <DomainServerConfigComponent onSaveConfig={handleSaveConfig} />
      <UploadServerComponent setServerData={setServerData} />
      <UploadDomainComponent setDomainData={setDomainData} />

      <Button type="primary" icon={<CloudUploadOutlined/>} onClick={handleProcessData} loading={processing}>
        发布任务
      </Button>

      {Array.isArray(resultData) && resultData.length > 0 ? (
        <DomainStatusComponent dataSource={resultData} columns={defaultColumns} />
      ) : (
        <div>
          <p>暂时没有数据</p>
          {/* You can add more details or components to customize the message */}
        </div>
      )}

    </div>
  );
};

export default DomainComponent;