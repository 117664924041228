import React from 'react';
import { Form, Input, Button, message, Row, Col } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const EncryptedDnsComponent = () => {


  return (
      <div>

      </div>
  );
};

export default EncryptedDnsComponent;
