import React from 'react';
import ECharts from 'echarts-for-react';
import * as echarts from 'echarts';
import './MapComponent.css'; // Make sure the correct CSS file is imported

// Import the GeoJSON data for the world map
import geoJsonData from '../world.zh.json'; // Use the correct file path

import {  dns_global_map_data } from '../config'
// Sample data for provinces and cities with counts
const data =  dns_global_map_data

const WorldMapComponent = () => {
  echarts.registerMap('world', geoJsonData);

  // Rest of the code remains the same
  const sortedData = data.map(country => ({
    name: country.name,
    value: country.count,
    cities: country.cities ? country.cities.sort((a, b) => b.count - a.count) : []
  }));

  const maxCount = Math.max(...sortedData.map(country => country.value));
  const minCount = Math.min(...sortedData.map(country => country.value));

  const options = {
    tooltip: {
      trigger: 'item',
      formatter: function (params) {
        const countryData = sortedData.find(c => c.name === params.name);
        if (countryData && countryData.cities.length > 0) {
          let tooltip = `${countryData.name}<br/>`;
          countryData.cities.forEach(city => {
            tooltip += `${city.name}: ${city.count}<br/>`;
          });
          return tooltip;
        }
        return `${params.name}<br/>${params.value} (Count)`;
      }
    },
    visualMap: {
      min: minCount, // 从数据中获取最小值
      max: maxCount, // 保持原有的最大值设置
      left: 'left',
      top: 'bottom',
      text: ['High', 'Low'],
      calculable: true,
      inRange: {
        // 使用更广泛的颜色范围以显示数量的区别
        color: ['#ffd700', '#ff7f50', '#f08080', '#fa8072', '#e9967a', '#f0e68c', '#bdb76b', '#9acd32', '#8fbc8f', '#00ced1', '#1e90ff']
      }
    },
    series: [
      {
        type: 'map',
        map: 'world', // Use 'world' instead of 'china'
        roam: true,
        itemStyle: {
          areaColor: '#cfcfcf', // Default area color, overridden by visualMap
          borderColor: '#111', // Color of the borders between countries
          borderWidth: 1, // Width of the borders
        },
        emphasis: {
          itemStyle: {
            areaColor: '#ffd700', // Bright color for emphasis
            borderColor: '#fff', // White borders for emphasized areas
            borderWidth: 2, // Make border thicker on hover
          },
          label: {
            show: true, // Ensure the label is shown on emphasis
            fontWeight: 'bold', // Optional: Make the label text bold on hover
          },
        },

        label: {
          normal: {
            show: true,
            color: '#fff'
          },
          emphasis: {
            color: '#fff'
          }
        },
        data: sortedData,
      },
    ],
  };

  return <ECharts className="map-container" option={options} style={{ height: '1200px' }} />;
};

export default WorldMapComponent;
