import React, { useState } from 'react';
import { TimePicker, Button } from 'antd';
import moment from 'moment';
import {SaveOutlined, SettingOutlined} from "@ant-design/icons";

const DomainServerConfigComponent = ({ onSaveConfig }) => {
  const [scheduledTime, setScheduledTime] = useState(moment('08:00', 'HH:mm'));
  // 定义状态以保存配置
  const [config, setConfig] = useState({
    dataSource: [], // 你的数据源
    columns: [],    // 你的列配置
    scheduledTime: '08:00', // 预设的每天运行时间
  });

  // 处理保存设置的逻辑
  const handleSaveConfig = (newConfig) => {
    // 更新状态中的配置
    setConfig(newConfig);
    // 这里可以执行其他保存配置的逻辑，比如发送到服务器
    console.log('保存的配置:', newConfig);
    // 将配置传递给父组件
    onSaveConfig(newConfig);
  };

  const handleTimeChange = (time, timeString) => {
    setScheduledTime(time);
    // 更新配置中的时间
    handleSaveConfig({ ...config, scheduledTime: timeString });
  };

  const handleSaveSchedule = () => {
    // 在这里处理保存设置的逻辑，可以发送到服务器或进行其他操作
    console.log('保存的时间:', scheduledTime.format('HH:mm'));
    // 更新配置中的时间
    handleSaveConfig({ ...config, scheduledTime: scheduledTime.format('HH:mm') });
  };

  return (
    <div>
      <div>
        <span>每天运行时间：</span>
        <TimePicker
          key={scheduledTime && scheduledTime.toString()} // Ensure a new key when scheduledTime changes
          value={scheduledTime}
          format="HH:mm"
          onChange={handleTimeChange}
          onOk={handleSaveSchedule}
        />


      </div>
      <Button type="primary" icon={<SaveOutlined />} onClick={handleSaveSchedule}>
        保存设置
      </Button>
    </div>
  );
};

export default DomainServerConfigComponent;
