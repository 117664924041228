import React, { useState } from 'react';
import { Table, Input, Button } from 'antd';
import { CSVLink } from 'react-csv';
import ECharts from 'echarts-for-react';
import 'echarts/lib/chart/bar';
import 'echarts/lib/chart/pie';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/title';

import './DataSourceComponent.css';

const DataSourceComponent = ({ data }) => {
  // 初始数据源
  const initialDataSource = Object.entries(data).map(([ownerName, quantity]) => ({
    key: ownerName,
    ownerName,
    quantity,
  }));

  const [dataSource, setDataSource] = useState(initialDataSource);
  const [chartOptions, setChartOptions] = useState(getBarChartOptions(dataSource));



  // 图表配置
  function getBarChartOptions(data) {
    return {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },
      xAxis: {
        type: 'category',
        data: data.map((item) => item.ownerName),
      },
      yAxis: {
        type: 'value',
      },
      series: [
        {
          data: data.map((item) => item.quantity),
          type: 'bar',
          showBackground: true,
          backgroundStyle: {
            color: 'rgba(180, 180, 180, 0.2)',
          },

          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowColor: 'rgba(0, 0, 0, 0.5)'  // 悬浮时的阴影效果
            }
          }
        },
      ],
    };
  }


  // 处理过滤逻辑
  const handleFilter = (value) => {
    const filteredData = initialDataSource.filter((item) => item.quantity >= parseInt(value, 10));
    setDataSource(filteredData);
    setChartOptions( getBarChartOptions(filteredData));
  };



  return (
    <div>
      {/* 导出数据按钮 */}
      <CSVLink data={dataSource} filename={'exported-data.csv'}>
        <Button className="dataSource-export-button">
          导出数据为 CSV
        </Button>
      </CSVLink>

      {/* 过滤功能 */}
      <Input
        className="dataSource-filter-input"
        placeholder="输入数量过滤条件"
        onChange={(e) => handleFilter(e.target.value)}
        style={{ margin: '10px 0' }}
      />


      {/* 统计图表 */}
      {chartOptions && (
        <ECharts option={chartOptions} style={{ height: '300px', marginTop: '20px' }} />
      )}

      {/* 数据表格 */}
      <Table
        className="dataSource-table"
        dataSource={dataSource}
        columns={[
          {
            title: '所有者名称',
            dataIndex: 'ownerName',
            key: 'ownerName',
            sorter: (a, b) => a.ownerName.localeCompare(b.ownerName),
            sortDirections: ['ascend', 'descend'],
            onFilter: (value, record) => record.ownerName.includes(value),
            render: (text) => (
              <a
                href={`https://www.baidu.com/s?wd=${encodeURIComponent(text)}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {text.length > 15 ? text.substring(0, 15) + '...' : text}
              </a>
            ),
          },
          {
            title: '数量',
            dataIndex: 'quantity',
            key: 'quantity',
            sorter: (a, b) => a.quantity - b.quantity,
          },
        ]}
      />
    </div>
  );
};

export default DataSourceComponent;
