import React, { useState, useEffect } from 'react';
import { Carousel, Spin, Alert } from 'antd';
import ReactEcharts from 'echarts-for-react';
import * as echarts from 'echarts'; // 确保导入了echarts

import axios from 'axios';
import config from '../../config'; // 导入配置文件

const TaskDataControlComponent = () => {
  const [taskData, setTaskData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${config.globalUrl}/dns-node-ctrl-rate-data-no-page`);
        const groupedData = response.data.reduce((acc, [time, nodeId, controlRate]) => {
          const key = time;
          if (!acc[key]) acc[key] = [];
          acc[key].push({ nodeId, controlRate });
          return acc;
        }, {});
        const tasks = Object.entries(groupedData).map(([time, nodes]) => ({
          time,
          nodes
        }));
        setTaskData(tasks);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching task data:', error);
        setError('Failed to fetch task data.');
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  if (loading) {
    return <Spin tip="Loading task data..." />;
  }

  if (error) {
    return <Alert message={error} type="error" />;
  }

  return (
    <Carousel autoplay>
      {taskData.map((task, index) => (
        <div key={index}>
          <ReactEcharts
            option={{
              title: {
                text: `Task Time: ${task.time}`, // 将时间作为标题显示
                textStyle: {
                  color: '#ccc'
                }
              },
              tooltip: {
                trigger: 'axis',
                axisPointer: {
                  type: 'shadow'
                },
                backgroundColor: 'rgba(0, 0, 0, 0.7)',
                textStyle: {
                  color: '#fff'
                }
              },
              legend: {
                data: task.nodes.map(node => `Node ${node.nodeId}`),
                textStyle: {
                  color: '#ccc'
                }
              },
              xAxis: {
                type: 'category',
                data: task.nodes.map(node => `Node ${node.nodeId}`),
                axisLabel: {
                  formatter: '{value}',
                  color: '#ccc'
                },
                axisLine: {
                  lineStyle: {
                    color: '#ccc'
                  }
                }
              },
              yAxis: {
                type: 'value',
                axisLabel: {
                  formatter: '{value}%',
                  color: '#ccc'
                },
                axisLine: {
                  lineStyle: {
                    color: '#ccc'
                  }
                },
                splitLine: {
                  lineStyle: {
                    color: '#08263a'
                  }
                }
              },
              series: [{
                name: 'Control Rate',
                type: 'bar',
                data: task.nodes.map(node => ({
                  value: node.controlRate,
                  itemStyle: {
                    color: `rgb(${Math.random() * 255}, ${Math.random() * 255}, ${Math.random() * 255})`
                  }
                })),
                barWidth: '60%'
              }],
              backgroundColor: '#003366',
              grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
              }
            }}
            style={{ height: '400px' }}
          />
        </div>
      ))}
    </Carousel>
  );
};

export default TaskDataControlComponent;
