import React, {useEffect, useState} from 'react';
import ECharts from 'echarts-for-react';
import * as echarts from 'echarts';
import './MapComponent.css';
import {Col, Row} from "antd"; // 确保创建并引入了相应的CSS文件
import { dns_map_data } from '../config'
// Sample data for provinces and cities with counts
const data = dns_map_data

const MapComponent = () => {
    // 在这里注册所有可能用到的地图
    echarts.registerMap('china', require('../china.json'));
    echarts.registerMap('北京市', require('../province/110000.json'));
    echarts.registerMap('天津市', require('../province/120000.json'));
    echarts.registerMap('河北省', require('../province/130000.json'));
    echarts.registerMap('山西省', require('../province/140000.json'));
    echarts.registerMap('内蒙古自治区', require('../province/150000.json'));
    echarts.registerMap('辽宁省', require('../province/210000.json'));
    echarts.registerMap('吉林省', require('../province/220000.json'));
    echarts.registerMap('黑龙江省', require('../province/230000.json'));
    echarts.registerMap('上海市', require('../province/310000.json'));
    echarts.registerMap('江苏省', require('../province/320000.json'));
    echarts.registerMap('浙江省', require('../province/330000.json'));
    echarts.registerMap('安徽省', require('../province/340000.json'));
    echarts.registerMap('福建省', require('../province/350000.json'));
    echarts.registerMap('江西省', require('../province/360000.json'));
    echarts.registerMap('山东省', require('../province/370000.json'));
    echarts.registerMap('河南省', require('../province/410000.json'));
    echarts.registerMap('湖北省', require('../province/420000.json'));
    echarts.registerMap('湖南省', require('../province/430000.json'));
    echarts.registerMap('广东省', require('../province/440000.json'));
    echarts.registerMap('广西壮族自治区', require('../province/450000.json'));
    echarts.registerMap('海南省', require('../province/460000.json'));
    echarts.registerMap('重庆市', require('../province/500000.json'));
    echarts.registerMap('四川省', require('../province/510000.json'));
    echarts.registerMap('贵州省', require('../province/520000.json'));
    echarts.registerMap('云南省', require('../province/530000.json'));
    echarts.registerMap('西藏自治区', require('../province/540000.json'));
    echarts.registerMap('陕西省', require('../province/610000.json'));
    echarts.registerMap('甘肃省', require('../province/620000.json'));
    echarts.registerMap('青海省', require('../province/630000.json'));
    echarts.registerMap('宁夏回族自治区', require('../province/640000.json'));
    echarts.registerMap('新疆维吾尔自治区', require('../province/650000.json'));
    echarts.registerMap('台湾省', require('../province/710000.json'));
    echarts.registerMap('香港特别行政区', require('../province/810000.json'));
    echarts.registerMap('澳门特别行政区', require('../province/820000.json'));
  const registerAllMaps = () => {

  };
  // 在组件加载时注册地图
  useEffect(() => {
    registerAllMaps();
  }, []);

  // 对每个省份的城市按从大到小的顺序排列
  const sortedData = data.map(province => ({
    name: province.name,
    adcode: province.adcode,
    value: province.count,
    cities: province.cities ? province.cities.sort((a, b) => b.count - a.count) : []
  }));
  // State to track the selected province
  const [selectedProvince, setSelectedProvince] = useState('山东省');

  // Find the selected province data
  const selectedProvinceData = sortedData.find((p) => p.name === selectedProvince);

  // Initialize currentData with the cities array of the selected province
  const [currentData, setCurrentData] = useState(selectedProvinceData ? selectedProvinceData.cities : []);

  // Calculate the maximum count value
  const maxCount = Math.max(...sortedData.map(province => province.value));


  // Handle province click event
  const handleProvinceClick = async (params) => {
    const provinceData = sortedData.find(p => p.name === params.name);
    // Get the clicked province name
    const clickedProvince = params.name;
    // Set the selected province in the state
    setSelectedProvince(clickedProvince);
    setCurrentData(provinceData.cities);
  };

  // Option for the left map (China map)
  const chinaMapOption = {
    tooltip: {
      trigger: 'item',
      formatter: function (params) {
        const provinceData = sortedData.find(p => p.name === params.name);
        // National map tooltip logic
        if (provinceData && provinceData.cities.length > 0) {
          let tooltip = `${provinceData.name}<br/>`;
          provinceData.cities.forEach(city => {
            tooltip += `${city.name}: ${city.count}<br/>`;
          });
          return tooltip;
        }
        return `${params.name}<br/>${params.value} (Count)`;
      },
      textStyle: {
          fontSize: 20, // Adjust the font size for tooltips in the provincial map
      },
    },
    visualMap: {
      min: 0,
      max: maxCount,
      left: 'left',
      top: 'bottom',
      text: ['High', 'Low'],
      calculable: true,
      inRange: {
        color: ['#313695', '#4575b4', '#74add1', '#abd9e9', '#e0f3f8', '#ffffbf', '#fee090', '#fdae61', '#f46d43', '#d73027', '#a50026']
      }
    },
    series: [
      {
        // Add a click event listener for provinces in the left map
        type: 'map',
        map: 'china',
        roam: true,
        itemStyle: {
            borderColor: 'rgba(147, 235, 248, 1)',
            borderWidth: 1,
            areaColor: {
              type: 'radial',
              x: 0.5,
              y: 0.5,
              r: 0.8,
              colorStops: [
                {
                  offset: 0,
                  color: 'rgba(147, 235, 248, 0)' // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: 'rgba(147, 235, 248, .2)' // 100% 处的颜色
                }
              ],
              globalCoord: false // 缺省为 false
            },
            shadowColor: 'rgba(128, 217, 248, 1)',
            shadowOffsetX: -2,
            shadowOffsetY: 2,
            shadowBlur: 10

        },
        emphasis: {
            areaColor: '#389BB7',
            borderWidth: 0
        },
        label: {
          normal: {
            show: true,
            color: '#fff',
            fontSize: 20, // Adjust the font size for labels in the national map
          },
          emphasis: {
            color: '#fff',
            fontSize: 20, // Adjust the font size for labels in the national map
          }
        },
        animation: true,
        animationDuration: 1000,
        animationEasing: 'cubicInOut',
        data: sortedData,
      },
    ],
  };

  // Option for the right map (Province map)
  const provinceMapOption = {
    tooltip: {
      trigger: 'item',
      formatter: function (params) {
        const provinceData = sortedData.find(p => p.name === params.name);


        // Province map tooltip logic
        const cityData = currentData.find(city => city.name === params.name);
        return cityData ? `${cityData.name}: ${cityData.count}<br/>` : '';

      },
      textStyle: {
          fontSize: 20, // Adjust the font size for tooltips in the provincial map
      },
    },
    visualMap: {
      min: 0,
      max:  Math.max(...currentData.map(city => city.count)),
      left: 'left',
      top: 'bottom',
      text: ['High', 'Low'],
      calculable: true,
      inRange: {
        color: ['#313695', '#4575b4', '#74add1', '#abd9e9', '#e0f3f8', '#ffffbf', '#fee090', '#fdae61', '#f46d43', '#d73027', '#a50026']
      }
    },
    series: [
      {
        type: 'map',
        mapType: selectedProvince,
        roam: true,
        itemStyle: {
          areaColor: '#ddb926', // Set the color for provinces in the provincial map
          borderColor: '#fff',
          borderWidth: 1,
        },
        label: {
          normal: {
            show: true,
            textStyle: {
              color: '#fff'
            },
            fontSize: 20, // Adjust the font size for labels in the national map
          },
          emphasis: {
            show: true,
            textStyle: {
              color: '#fff'
            },
            fontSize: 20, // Adjust the font size for labels in the national map
          }
        },
        animation: true,
        animationDuration: 1000,
        animationEasing: 'cubicInOut',
        // Add color to individual cities in the provincial map
        data: currentData.map(city => ({
          name: city.name,
          value: city.count,
        })),
      },
    ],

  };

  return (
    <div className="echarts-container">
      <Row gutter={20} style={{ height: '100%' }} justify="space-around">
        <Col span={12}>
          <div className="map-left" style={{ height: '100%' }}>
            <ECharts option={chinaMapOption} onEvents={{ click: handleProvinceClick }} style={{ width: '100%', height: '100%' }} />
          </div>
        </Col>
        <Col span={12}>
          <div className="map-right" style={{ height: '100%' }}>
            <ECharts option={provinceMapOption} style={{ width: '100%', height: '100%' }} />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default MapComponent;